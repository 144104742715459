import * as React from "react";
import { Box, Paper, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import testImage from "../assets/images/image_scanner_test.jpg";
import configImage from "../assets/images/image_configuration2.jpg";
import Button, { ButtonProps } from "@mui/material/Button";
import { Link } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  padding: theme.spacing(1),
  textAlign: "center",
}));

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText("rgba(232, 88, 17)"),
  backgroundColor: "rgba(232, 88, 17)",
  "&:hover": {
    backgroundColor: "rgba(232, 88, 17)",
  },
}));

export default function ScannerDocumentationPage() {
  return (
    <Box sx={{ flexGrow: 1, bgcolor: "rgba(246,240,240)" }}>
      <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
        <Grid item xs={6} md={6}>
          <Link to={"/scanner-ais"}>
            <Box
              component={"img"}
              sx={{ width: "100%", height: "60%", display: "block" }}
              src={testImage}
            ></Box>
          </Link>
          <Item>
            <Typography variant="h6" gutterBottom>
              <b>Scanner Test-Tool</b>
            </Typography>
            <Typography paragraph variant="body1" gutterBottom>
              Met de Scanner Test-Tool doorloopt u enkele tests om uw scanner te
              controleren op bekende fouten in de configuratie.
              <br />
            </Typography>
            <br />
            <ColorButton
              variant="contained"
              href="scanner-ais"
              sx={{ mr: 1, color: "white" }}
            >
              Scanner Test-Tool
            </ColorButton>
          </Item>
        </Grid>
        <Grid item xs={6} md={6}>
          <Link to={"/scanner-documentation"}>
            <Box
              component={"img"}
              sx={{ width: "100%", height: "60%", display: "block" }}
              src={configImage}
            ></Box>
          </Link>
          <Item>
            <Typography variant="h6" gutterBottom>
              <b>Scanner Configuratie-Tool</b>
            </Typography>
            <Typography paragraph variant="body1" gutterBottom>
              In de Scanner Configuratie-Tool vindt u de stappen voor het juist
              instellen van uw scanner. Daarnaast zijn er diverse instelbladen
              te vinden.
              <br />
              <br />
            </Typography>
            <ColorButton
              variant="contained"
              href="scanner-documentation"
              sx={{ mr: 1, color: "white" }}
            >
              Scanner Configuratie-Tool
            </ColorButton>
          </Item>
        </Grid>
      </Grid>
    </Box>
  );
}
