import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {BrowserRouter} from "react-router-dom";

import reportWebVitals from './reportWebVitals';
import {CssBaseline} from "@mui/material";
import {createTheme, ThemeProvider} from '@mui/material/styles';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

const themeNMVO = createTheme({
    palette: {
        background: {
            default: 'rgba(246,240,240)'
        }
    }
});

root.render(
    <React.StrictMode>
        <ThemeProvider theme={themeNMVO}>
            <CssBaseline>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </CssBaseline>
        </ThemeProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
