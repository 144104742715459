import * as React from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Paper,
  Typography,
  Card,
  CardMedia,
} from "@mui/material";
import cgmAis from "../assets/images/cgm.jpg";
import pharmaAis from "../assets/images/pharma_partners.jpg";
import smartmedAis from "../assets/images/smartmed.png";
import Grid from "@mui/material/Grid";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText("rgba(232, 88, 17)"),
  backgroundColor: "rgba(232, 88, 17)",
  "&:hover": {
    backgroundColor: "rgba(232, 88, 17)",
  },
}));

export default function ScannerAisPage() {
  const [sep, setSep] = React.useState("");
  const [chosenImage, setChosenImage] = React.useState("");
  const [selected1, setSelected1] = React.useState(false);
  const [selected2, setSelected2] = React.useState(false);
  const [selected3, setSelected3] = React.useState(false);
  const [disabled, setDisabled] = React.useState(true);

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setSep("[GS]");
    setChosenImage("CGM");
    setSelected1(true);
    setDisabled(false);
    setSelected2(false);
    setSelected3(false);
  };

  const handleClick2 = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setSep("|");
    setChosenImage("Pharma Partners");
    setSelected1(false);
    setDisabled(false);
    setSelected2(true);
    setSelected3(false);
  };

  const handleClick3 = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setSep("~");
    setChosenImage("SmartMed");
    setSelected1(false);
    setDisabled(false);
    setSelected2(false);
    setSelected3(true);
  };

  return (
    <Box>
      <Alert sx={{ py: 2, mb: 2 }} severity="info">
        <AlertTitle>
          <b>Scanner Tools</b>
        </AlertTitle>
        De Scanner-Tool is nog niet geschikt voor alle AIS-leveranciers. Staat
        uw AIS-leverancier er nog niet tussen? Maak dan kenbaar bij uw
        AIS-leverancier dat u graag gebruik zou willen maken van de NMVO Scanner
        Test-Tool. Uw AIS-leverancier kan contact opnemen met de NMVO.
      </Alert>

      <Paper sx={{ p: 2, mb: 2 }}>
        <Typography variant="subtitle1" gutterBottom>
          <b>Scanner Test-Tool</b> Selecteer uw AIS-leverancier.
        </Typography>

        <Grid container spacing={2} columns={{ xs: 4, sm: 8, md: 12 }}>
          <Grid item xs={6} md={6}>
            <Card sx={{ width: 1 }}>
              <Box sx={{ position: "relative" }} onClick={handleClick}>
                <CardMedia
                  component="img"
                  height="200"
                  image={cgmAis}
                  sx={{ maxWidth: 300 }}
                />
                {selected1 && (
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      width: "100%",
                      bgcolor: "rgba(0, 0, 0, 0.54)",
                      color: "white",
                      padding: "10px",
                      border: "2px",
                    }}
                  >
                    <Typography variant="h6">Geselecteerd</Typography>
                  </Box>
                )}
              </Box>
            </Card>
          </Grid>
          <Grid item xs={6} md={6}>
            <Card sx={{ width: 1 }}>
              <Box sx={{ position: "relative" }} onClick={handleClick2}>
                <CardMedia component="img" height="200" image={pharmaAis} />
                {selected2 && (
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      width: "100%",
                      bgcolor: "rgba(0, 0, 0, 0.54)",
                      color: "white",
                      padding: "10px",
                      border: "2px",
                    }}
                  >
                    <Typography variant="h6">Geselecteerd</Typography>
                  </Box>
                )}
              </Box>
            </Card>
          </Grid>
          <Grid item xs={6} md={6}>
            <Card sx={{ width: 1 }}>
              <Box sx={{ position: "relative" }} onClick={handleClick3}>
                <CardMedia component="img" height="200" image={smartmedAis} />
                {selected3 && (
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      width: "100%",
                      bgcolor: "rgba(0, 0, 0, 0.54)",
                      color: "white",
                      padding: "10px",
                      border: "2px",
                    }}
                  >
                    <Typography variant="h6">Geselecteerd</Typography>
                  </Box>
                )}
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Paper>
      <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
        <ColorButton
          variant="contained"
          color="inherit"
          sx={{ mr: 1 }}
          href={"/scanner-home"}
        >
          Vorige
        </ColorButton>
        <Box sx={{ flex: "1 1 auto" }} />
        <ColorButton variant="contained" sx={{ mr: 1 }} disabled={disabled}>
          <Link
            to="/scanner-test"
            state={{ separator: sep, image: chosenImage }}
            style={{ textDecoration: "none", color: "white" }}
          >
            Volgende
          </Link>
        </ColorButton>
      </Box>
    </Box>
  );
}
