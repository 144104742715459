import * as React from "react";
import {
  Alert,
  AlertTitle,
  Grid,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Box from "@mui/material/Box";
import ClearIcon from "@mui/icons-material/Clear";
import cgmAis from "../assets/images/cgm.jpg";
import pharmaAis from "../assets/images/pharma_partners.jpg";
import smartmedAis from "../assets/images/smartmed.png";
import { debounce } from "lodash";

interface ScannerTestCaseProps {
  barcode: string;
  testString: string;
  requiresCapslock: boolean;
  separator: any;
  activeStep: number;
  description: string;
  failureDescription: string;
  onSuccess: () => void;
  onFailure: () => void;
  pharmacyChosen: any;
  capsLockState: boolean;
}

export function ScannerTestCase(props: ScannerTestCaseProps) {
  const [input, setInput] = React.useState("");
  const input2 = React.useRef("");
  const [valid, setValid] = React.useState<boolean>(false);
  const [complete, setComplete] = React.useState<boolean>(false);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const capsLock = React.useRef(props.capsLockState);
  const [capsLockState, setCapsLockState] = React.useState<boolean>(false);
  const [showNoEnter, setShowNoEnter] = React.useState<boolean>(false);
  const [showAlarm, setShowAlarm] = React.useState<boolean>(true);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    input2.current = e.currentTarget.value;
    setInput(e.currentTarget.value);
  };

  const runValidation = React.useCallback(() => {
    setComplete(true);
    if (props.requiresCapslock) {
      setShowAlarm(false);
    } else {
      setShowAlarm(true);
    }

    if (
      input2.current ===
      props.testString.replace("[separator]", props.separator)
    ) {
      setValid(true);
      props.onSuccess();
    } else {
      setValid(false);
      props.onFailure();
    }
    setCapsLockState(capsLock.current);
  }, [setComplete, setValid, props]);

  const reset = () => {
    if (inputRef?.current !== null) {
      inputRef.current.focus();
    }
    setComplete(false);
    setValid(false);
    setInput("");
    setShowNoEnter(false);
    input2.current = "";
  };

  const pharmacyChosen = () => {
    const pharma = props.pharmacyChosen;
    switch (pharma) {
      case "CGM" : return cgmAis;
      case 'Pharma Partners': return pharmaAis;
      default : return smartmedAis;
    }
  };

  const debouncedSearch = React.useMemo(
    () =>
      debounce(() => {
        runValidation();
        setShowNoEnter(true);
      }, 500),
    [runValidation, setShowNoEnter]
  );

  React.useEffect(() => {
    return () => {
      reset();
      window.addEventListener("keydown", (e) => handleKeyDown(e));
      window.addEventListener("keyup", (e) => handleKeyUp(e));
    };
  }, [props, inputRef]);

  const handleKeyBoardDown = (e: any) => {
    if (e.keyCode === 13) {
      runValidation();
      setShowNoEnter(false);
      debouncedSearch.cancel();
    } else {
      if (
        input2.current.length > 0 &&
        e.keyCode !== 13 &&
        input2.current.charAt(input2.current.length - 1) !== "\n" &&
        /[a-zA-Z]/g.test(input2.current)
      ) {
        debouncedSearch();
      }
    }
  };

  const handleKeyDown = (e: any) => {
    capsLock.current = !!e.getModifierState("CapsLock");
    setCapsLockState(capsLock.current);
  };

  const handleKeyUp = (e: any) => {
    capsLock.current = !!e.getModifierState("CapsLock");
    setCapsLockState(capsLock.current);
  };

  const handleClearClick = (e: React.MouseEvent) => {
    reset();
  };
  return (
    <Box>
      <Grid container>
        <Grid item xs={3} md={3}>
          <Box
            component={"img"}
            sx={{ width: 1, display: "block", my: 2 }}
            src={props.barcode}
          ></Box>
        </Grid>
        <Grid item xs={6} md={6}></Grid>
        <Grid item xs={3} md={3}>
          <Typography align={"center"} variant={"body2"}>
            <b>Gekozen AIS-leverancier:</b>
          </Typography>
          <Box
            component={"img"}
            sx={{ width: 1, display: "block", my: 2, alignSelf: "center" }}
            src={pharmacyChosen()}
          ></Box>
        </Grid>
      </Grid>
      <Grid item xs={12} md={12}>
        <TextField
          fullWidth
          inputRef={inputRef}
          onKeyDown={handleKeyBoardDown}
          id="standard-error-helper-text"
          label="Scanner Invoer"
          InputProps={{
            endAdornment: (
              <IconButton
                sx={{ visibility: input ? "visible" : "hidden" }}
                onClick={handleClearClick}
              >
                <ClearIcon />
              </IconButton>
            ),
          }}
          value={input}
          onChange={(e) => handleChange(e)}
        />
        <Stack sx={{ mt: 2 }} spacing={1}>
          <Alert severity="info" style={{ whiteSpace: "pre-line" }}>
            <AlertTitle>
              <b>Instructies</b>
            </AlertTitle>
            {props.description}
          </Alert>
        </Stack>
        <Stack sx={{ mt: 2 }} spacing={1}>
          {!props.requiresCapslock && capsLockState && (
            <Alert severity="error">
              Zet uw capslock UIT om de test goed uit te voeren
            </Alert>
          )}
          {props.requiresCapslock && !capsLockState && showAlarm && (
            <Alert severity="error">
              Zet uw capslock AAN om de test goed uit te voeren
            </Alert>
          )}
          {showNoEnter && (
            <Alert severity="warning">Geen automatische enter ingesteld!</Alert>
          )}
          {complete && !valid && (
            <Alert severity="error">
              <AlertTitle>
                <b>Test mislukt, onjuiste invoer!</b>
              </AlertTitle>
              Verwacht:{" "}
              <b>{props.testString.replace("[separator]", props.separator)}</b>
              <br />
              Gevonden: <b>{input}</b>
              <br />
              Mogelijke oorzaak: <b>{props.failureDescription}</b>
            </Alert>
          )}
          {complete && valid && (
            <Alert severity="success">
              <AlertTitle>Test succesvol</AlertTitle>
              Verwacht:{" "}
              <b>{props.testString.replace("[separator]", props.separator)}</b>
              <br />
              Gevonden: <b>{input}</b>
            </Alert>
          )}
        </Stack>
      </Grid>
    </Box>
  );
}
