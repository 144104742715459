import * as React from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Paper,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ButtonProps } from "@mui/material/Button";
import { Link, useLocation } from "react-router-dom";

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText("rgba(255, 255, 255)"),
  backgroundColor: "rgba(255, 255, 255)",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255)",
  },
}));

export default function ScannerTestLandingPage() {
  const location = useLocation();
  const [locationState, setLocationState] = React.useState({
    separator: "",
    image: "",
  });
  const capsLock = React.useRef("false");

  React.useEffect(() => {
    if (location.state) {
      let _state = location.state as any;
      setLocationState(_state);
      window.addEventListener("keyup", (e) => handleKeyDown(e));
      window.addEventListener("keydown", (e) => handleKeyDown(e));
      window.addEventListener("keypress", (e) => handleKeyPress(e));
    }
  }, [location]);

  const handleKeyPress = (e: any) => {
    if (
      e.keyCode === 13 &&
      !window.location.href.includes("scanner-test-") &&
      window.location.href.includes("scanner-test")
    ) {
      // @ts-ignore
      document.getElementById("ButtonClick").click();
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.getModifierState("CapsLock")) {
      capsLock.current = "true";
    } else {
      capsLock.current = "false";
    }
  };

  return (
    <Box>
      <Paper sx={{ p: 2, mb: 2 }}>
        <Typography variant="h6" gutterBottom>
          <b>NMVO Scanner Test-Tool</b>
        </Typography>

        <Typography paragraph variant="body1" gutterBottom>
          Met de Scanner Test-Tool worden de configuratie, of instellingen, van
          uw scanner gecontroleerd. Zojuist heeft u de AIS-leverancier
          geselecteerd. Deze informatie wordt gebruikt voor de controle.{" "}
          <br></br>
          <br></br>Naast uw AIS-instellingen, wordt getest op de volgende zaken:
          <ul>
            <li>
              Hoe gaat de scanner om met het al dan niet aanstaan van de
              CapsLock-toets (CapsLock-override)?
            </li>
            <li>Staat de juiste separator voor het AIS ingesteld?</li>
            <li>
              Wordt er een{" "}
              <b>
                <code>Enter</code>
              </b>{" "}
              meegestuurd aan het eind van een scan?
            </li>
            <li>Leest de scanner alle speciale tekens?</li>
            <li>
              Kan de scanner witte 2D-codes op donkere achtergronden scannen?
            </li>
          </ul>
        </Typography>

        <Typography paragraph variant="body1" gutterBottom>
          <b>Belangrijk:</b>
          <ol>
            <li>
              Met het testen van de scanner verandert u <u>geen</u>{" "}
              instellingen.
            </li>
            <li>Zorg ervoor dat de scanner is aangesloten op uw computer.</li>
            <li>
              Bij elke test staan instructies, lees deze goed voordat u scant.
            </li>
            <li>
              Voor sommige tests moet u de CapsLock aan/uitzetten. Dit wordt
              weergeven.
            </li>
            <li>
              Mocht een test mislukken, kunt u gewoon doorgaan naar de volgende
              test.
            </li>
          </ol>
        </Typography>

        <Alert sx={{ py: 2, mb: 2 }} severity="info">
          <AlertTitle>Opmerking</AlertTitle>
          Als u vragen of opmerkingen heeft over de tool, dan horen wij die
          graag via &nbsp;<a href="mailto:apotheek@nmvo.nl">apotheek@nmvo.nl</a>
          .
        </Alert>

        <Link
          to="/scanner-test-start"
          state={{
            separator: locationState.separator,
            image: locationState.image,
            capsLockState: capsLock.current,
          }}
          style={{ textDecoration: "none" }}
        >
          <ColorButton
            sx={{ width: 0.1, height: 0.1, color: "white" }}
            id={"ButtonClick"}
          ></ColorButton>
        </Link>

        <Typography align={"center"} color={"rgba(232, 88, 17)"}>
          <b>
            Druk op de <code>Enter</code>-toets om te starten
          </b>
        </Typography>
      </Paper>
    </Box>
  );
}
