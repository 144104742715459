import * as React from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Paper,
  Step,
  StepButton,
  Stepper,
  Typography,
  StepLabel,
} from "@mui/material";
import barcode1 from "../assets/testTool/barcode1.png";
import barcode2 from "../assets/testTool/barcode2.png";
import barcode3 from "../assets/testTool/barcode3.png";
import barcode4 from "../assets/testTool/barcode4.png";
import barcode5 from "../assets/testTool/barcode5.png";
import { ScannerTestCase } from "../components/ScannerTestCase";
import { styled } from "@mui/material/styles";
import { ButtonProps } from "@mui/material/Button";
import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText("rgba(232, 88, 17)"),
  backgroundColor: "rgba(232, 88, 17)",
  "&:hover": {
    backgroundColor: "rgba(232, 88, 17)",
  },
}));

const steps = [
  {
    label: "Scan met CapsLock UIT",
    barcode: barcode1,
    testString: "MixedCASE",
    requiresCapslock: false,
    separator: "",
    description:
      "• Deze test controleert of de scanner de tekst uit de 2D-code kan scannen.\n" +
      "• Voor deze test moet de CapsLock-toets UIT staan.\n",
    failureDescription:
      "Mogelijk staat de automatische Enter niet ingesteld of is er een ander probleem.",
  },
  {
    label: "Scan met CapsLock AAN",
    barcode: barcode1,
    testString: "MixedCASE",
    requiresCapslock: true,
    separator: "",
    description:
      "• Deze test controleert of de CapsLock-toets invloed heeft op de scan.\n" +
      "• BELANGRIJK! Voor deze test moet de CapsLock-toets AAN staan.\n",
    failureDescription:
      "De scanner is gevoelig voor het aanstaan van de CapsLock-toets en moet opnieuw worden ingesteld.",
  },
  {
    label: "Snelheid van de scanner",
    barcode: barcode2,
    testString:
      "This1IsA2Long3Mixed4CASEstring5That7MAY8CauseProblems8With9Keyboard0Speed",
    requiresCapslock: false,
    separator: "",
    description:
      "• Deze test controleert of de snelheid van de scanner goed is ingesteld.\n" +
      "• Voor deze test moet de CapsLock-toets UIT staan.",
    failureDescription:
      "De snelheid van de scanner staat mogelijk te hoog ingesteld.",
  },
  {
    label: "Scanner geschikt voor AIS (separator)",
    barcode: barcode3,
    testString: "01012345600000051725123121aBcDeF[separator]101a2b3c4d",
    requiresCapslock: false,
    separator: "",
    description:
      "• Deze controleert of de separator (splitsingssymbool) juist staat ingesteld voor uw AIS.\n" +
      "• De separator is een AIS-specifieke instelling welke nodig is om de gescande 2D-code juist te vertalen voor het AIS-systeem.\n" +
      "• Voor deze test moet de CapsLock-toets UIT staan.\n",
    failureDescription:
      "De ingestelde separator van de scanner komt mogelijk niet overeen met uw AIS.",
  },
  {
    label: "Witte code donkere achtergrond",
    barcode: barcode4,
    testString: "01012345600000051725123121aBcDeF[separator]101a2b3c4d",
    requiresCapslock: false,
    separator: "",
    description:
      "• Deze test controleert of de scanner 2D-codes op een donkere achtergrond kan lezen (inverse 2D codes).\n" +
      "• Voor deze test moet de CapsLock-toets UIT staan.\n" +
      "• Mocht u deze code niet kunnen scannen, is uw scanner mogelijk niet geconfigureerd om inverse codes te scannen.",
    failureDescription:
      "De ingestelde separator van de scanner komt mogelijk niet overeen met uw AIS.",
  },
  {
    label: "Speciale characters",
    barcode: barcode5,
    testString: "AbcdefghijklmnopQrstuvwxYz0123456789_>=?<:!\"%'()*+-,./;&::",
    requiresCapslock: false,
    separator: "",
    description:
      "• Deze test controleert of de scanner de juiste speciale leestekens doorstuurt.\n" +
      "• Voor deze test moet de CapsLock-toets UIT staan.\n",
    failureDescription:
      "De scanner kan niet alle speciale tekens lezen of er is een probleem met de toetsenbordconfiguratie (lay-out QWERTY/AZERTY icm. instellingen PC).",
  },
];

export default function ScannerTestPage() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [failedSteps, setFailedSteps] = React.useState<{
    [k: number]: boolean;
  }>({});
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});
  const location = useLocation();
  const [locationState, setLocationState] = React.useState({
    separator: "",
    image: "",
    capsLockState: false,
  });

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  React.useEffect(() => {
    if (location.state) {
      let _state = location.state as any;
      setLocationState(_state);
    }
  }, [location]);

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    "&:last-child td, &:last-child th": {
      border: 0,
    },
  }));

  function testsData(id: number, label: string, status: string) {
    return { id, label, status };
  }

  const rows = [
    testsData(1, steps[0].label, completed[0] ? "Geslaagd" : "Mislukt"),
    testsData(2, steps[1].label, completed[1] ? "Geslaagd" : "Mislukt"),
    testsData(3, steps[2].label, completed[2] ? "Geslaagd" : "Mislukt"),
    testsData(4, steps[3].label, completed[3] ? "Geslaagd" : "Mislukt"),
    testsData(5, steps[4].label, completed[4] ? "Geslaagd" : "Mislukt"),
    testsData(6, steps[5].label, completed[5] ? "Geslaagd" : "Mislukt"),
  ];

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.entries(completed).filter(([k, v]) => v).length;
  };

  const ifFailedSteps = () => {
    return Object.entries(failedSteps).filter(([k, v]) => v).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps();
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has not been completed
          steps.findIndex(
            (step, i) =>
              !(
                i in
                Object.fromEntries(
                  Object.entries(completed).filter(([k, v]) => v)
                )
              )
          )
        : activeStep + 1;

    if (!completed[activeStep]) {
      const newFailed = failedSteps;
      newFailed[activeStep] = true;
      setFailedSteps(newFailed);
    }
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => () => {
    if (!completed[activeStep]) {
      const newFailed = failedSteps;
      newFailed[activeStep] = true;
      setFailedSteps(newFailed);
    }
    setActiveStep(step);
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
    setFailedSteps({});
  };

  const isStepFailed = (step: number) => {
    if (!completed[activeStep]) {
      completed[activeStep] = false;
    }
    return failedSteps[step];
  };

  return (
    <Box>
      <div>
        {isLastStep() ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              <b>Alle stappen voltooid - Je bent klaar</b>
              <br />
            </Typography>
            <Typography paragraph variant="body1" gutterBottom>
              <br />
              Hieronder vindt u een overzicht van de testresultaten. Als niet
              alle resultaten <b>Geslaagd</b> (groen) zijn, is het raadzaam om
              de instellingen van de scanner te controleren. Hiervoor kunt u
              gebruik maken van de Scanner Configuratie-Tool, hier vindt u
              verdere informatie om de scanner opnieuw in te stellen.
            </Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 700 }} aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Testnummer</StyledTableCell>
                    <StyledTableCell>Beschrijving</StyledTableCell>
                    <StyledTableCell>Testresultaat</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows.map((row) => (
                    <StyledTableRow key={row.id}>
                      <StyledTableCell component="th" scope="row">
                        {row.id}
                      </StyledTableCell>
                      <StyledTableCell>{row.label}</StyledTableCell>
                      <StyledTableCell
                        sx={{
                          color: "red",
                          fontWeight: "bold",
                          ...(row.status === "Geslaagd" && {
                            color: "green",
                            fontWeight: "bold",
                          }),
                        }}
                      >
                        {row.status}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              {ifFailedSteps() ? (
                <ColorButton
                  variant="contained"
                  href={"scanner-documentation"}
                  sx={{ color: "white" }}
                >
                  Configuratie-Tool
                </ColorButton>
              ) : (
                <ColorButton
                  variant="contained"
                  onClick={handleReset}
                  sx={{ color: "white" }}
                >
                  Test opnieuw starten
                </ColorButton>
              )}
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Grid container spacing={2}>
              <Grid item xs={10} md={10}>
                <Paper sx={{ mt: 4, px: 2, pb: 2 }}>
                  <Typography sx={{ mt: 2, mb: 1 }} variant="h6" gutterBottom>
                    <b>Test {activeStep + 1} - </b>
                    {steps[activeStep].label}
                  </Typography>
                  <ScannerTestCase
                    barcode={steps[activeStep].barcode}
                    testString={steps[activeStep].testString}
                    requiresCapslock={steps[activeStep].requiresCapslock}
                    separator={locationState.separator}
                    activeStep={activeStep}
                    onSuccess={() => {
                      completed[activeStep] = true;
                      failedSteps[activeStep] = false;
                    }}
                    onFailure={() => {
                      failedSteps[activeStep] = true;
                      completed[activeStep] = false;
                    }}
                    description={steps[activeStep].description}
                    failureDescription={steps[activeStep].failureDescription}
                    pharmacyChosen={locationState.image}
                    capsLockState={locationState.capsLockState}
                  />
                </Paper>
                <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                  <ColorButton
                    variant="contained"
                    color="inherit"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1, color: "white" }}
                  >
                    Vorige
                  </ColorButton>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <ColorButton
                    variant="contained"
                    onClick={handleNext}
                    sx={{ mr: 1, color: "white" }}
                  >
                    Volgende
                  </ColorButton>
                </Box>
              </Grid>
              <Grid item xs={2} md={2}>
                <Stepper
                  sx={{ m: 2 }}
                  nonLinear
                  activeStep={activeStep}
                  orientation="vertical"
                >
                  {steps.map(({ label }, index) => {
                    const labelProps: {
                      optional?: React.ReactNode;
                      error?: boolean;
                    } = {};
                    if (isStepFailed(index)) {
                      labelProps.optional = (
                        <Typography variant="caption" color="error">
                          Alert message
                        </Typography>
                      );
                      labelProps.error = true;
                    }

                    return (
                      <Step
                        key={`${label}-${index}`}
                        completed={completed[index]}
                        sx={{
                          "& .MuiStepLabel-root .Mui-completed": {
                            color: "green",
                          },
                          "& .MuiStepLabel-root .Mui-active": {
                            color: "orange",
                          },
                          m: 1,
                          width: "100%",
                        }}
                      >
                        <StepButton color="inherit" onClick={handleStep(index)}>
                          <StepLabel {...labelProps}>{label}</StepLabel>
                        </StepButton>
                      </Step>
                    );
                  })}
                </Stepper>
              </Grid>
            </Grid>
          </React.Fragment>
        )}
      </div>
    </Box>
  );
}
