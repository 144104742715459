import { FC } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import remarkimage from "../assets/images/newland_scanning_preference.png";
import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1100,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

// Define the props interface for the modal
interface RemarksModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
}

const ColorButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.getContrastText("rgba(232, 88, 17)"),
  backgroundColor: "rgba(232, 88, 17)",
  "&:hover": {
    backgroundColor: "rgba(232, 88, 17)",
  },
}));

const RemarksModal: FC<RemarksModalProps> = ({ isOpen, onRequestClose }) => {
  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onRequestClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Opmerking:
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Indien je de online test wil uitvoeren met een Newland scanner, gelieve de scanner dan even in de mobile mode te plaatsen. Vergeet deze achteraf niet terug op de normal mode te plaatsen.
          </Typography>
          <img src={remarkimage} alt='Newland scanner settings' />
          <ColorButton
          variant="contained"
          sx={{
            mt: 2,
            mb: 0,
            color: "white",
            justify: "space-between",

          }} onClick={onRequestClose}>Afsluiten</ColorButton>
        </Box>
      </Modal>
    </div>
  );
}
export default RemarksModal;
