import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ScannerTestLandingPage from "./pages/ScannerTestLandingPage";
import ScannerTestPage from "./pages/ScannerTestPage";
import ScannerDocumentationPage from "./pages/ScannerDocumentationPage";
import ScannerAisPage from "./pages/ScannerAisPage";
import ScannerHomePage from "./pages/ScannerHomePage";
import { Link, Navigate, Outlet, Route, Routes } from "react-router-dom";
import logo from "./assets/images/logonmvo2.png";
import ArticleIcon from "@mui/icons-material/Article";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import HomeIcon from "@mui/icons-material/Home";
import { Container } from "@mui/material";

const drawerWidth = 280;

const SideBarLayout = () => (
  <Box sx={{ display: "flex" }}>
    <Drawer
      variant="permanent"
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" },
      }}
    >
      <Toolbar />
      <Box
        sx={{
          overflow: "auto",
        }}
      >
        <Box
          component={"img"}
          sx={{
            width: "70%",
            mx: 4,
          }}
          src={logo}
          alt="NVMO Logo"
        ></Box>
        <List>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="scanner-home-page">
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Startpagina" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="scanner-ais">
              <ListItemIcon>
                <QrCodeScannerIcon />
              </ListItemIcon>
              <ListItemText primary="Scanner Test-Tool" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton component={Link} to="scanner-documentation">
              <ListItemIcon>
                <ArticleIcon />
              </ListItemIcon>
              <ListItemText primary="Scanner Configuratie-Tool" />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Drawer>
    <Container>
      <Outlet />
    </Container>
  </Box>
);

export default function App() {
  return (
    <Box sx={{ display: "flex" }}>
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          bgcolor: "rgba(1, 72, 108)",
          width: `calc(100% - ${drawerWidth}px)`,
          ml: `${drawerWidth}px`,
        }}
      >
        <Toolbar />
      </AppBar>
      <Toolbar />
      <Box component="main" sx={{ flexGrow: 1, pt: 2 }}>
        <Toolbar />
        <Routes>
          <Route element={<SideBarLayout />}>
            <Route path="scanner-home-page" element={<ScannerHomePage />} />
          </Route>
          <Route element={<SideBarLayout />}>
            <Route path="scanner-test" element={<ScannerTestLandingPage />} />
            <Route path="scanner-test-start" element={<ScannerTestPage />} />
            <Route
              path="scanner-documentation"
              element={<ScannerDocumentationPage />}
            />
            <Route path="scanner-ais" element={<ScannerAisPage />} />
            <Route
              path="*"
              element={<Navigate to="scanner-home-page" replace />}
            />
          </Route>
        </Routes>
      </Box>
    </Box>
  );
}
